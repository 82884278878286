exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-examples-js": () => import("./../../../src/pages/contato/examples.js" /* webpackChunkName: "component---src-pages-contato-examples-js" */),
  "component---src-pages-contato-file-upload-js": () => import("./../../../src/pages/contato/file-upload.js" /* webpackChunkName: "component---src-pages-contato-file-upload-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-contato-obrigada-js": () => import("./../../../src/pages/contato/obrigada.js" /* webpackChunkName: "component---src-pages-contato-obrigada-js" */),
  "component---src-pages-imoveis-index-js": () => import("./../../../src/pages/imoveis/index.js" /* webpackChunkName: "component---src-pages-imoveis-index-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

